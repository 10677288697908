import React from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { RTE_SETTINGS } from './variables';
import DynamicOptionList from './dynamic-option-list.js';
import IntlMessages from './language-provider/IntlMessages';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomLogic } from './customComponents/CustomLogic';
import { CalculatorLogic } from './customComponents/MUI_Calculator.jsx';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MyTextField } from './customComponents/MUI_TextField.jsx';
import { FormElementIcon } from './form-element-icons.jsx';
import { FileAttachment_Upload } from './customComponents/FileAttachment_Upload.jsx';
import FormHelperText from '@mui/material/FormHelperText';
import StyledWrapper from './style';
import { FormControl, Checkbox, TextField } from '@mui/material';

export default class FormElementsEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			element: this.props.element,
			data: this.props.data,
			dirty: false
		};
		this.props.element.limit_value = this.props.element.hasOwnProperty('limit_value')
			? this.props.element.limit_value
			: 0;
	}

	editElementProp(elemProperty, targProperty, e) {
		// elemProperty could be content or label
		// targProperty could be value or checked
		const this_element = this.state.element;
		this_element[elemProperty] = e.target[targProperty];

		this.setState(
			{
				element: this_element,
				dirty: true
			},
			() => {
				if (targProperty === 'checked') {
					this.updateElement();
				}
			}
		);
	}

	customEditElementProp(targProperty, values) {
		const this_element = this.state.element;
		this_element[targProperty] = values;
		this.setState(
			{
				element: this_element,
				dirty: true
			},
			() => {
				this.updateElement();
			}
		);
	}

	instructionDataHandler = (content, delta, source, editor) => {
		if (source === 'user') {
			const this_element = this.state.element;
			this_element['content'] = content;
			this.setState({ element: this_element, dirty: true });
		}
	};

	updateElement() {
		const this_element = this.state.element;
		// to prevent ajax calls with no change
		if (this.state.dirty) {
			this.props.updateElement(this_element);
			this.setState({ ...this.state, dirty: false });
		}
	}

	render() {
		const queryParameters = new URLSearchParams(window.location.search);
		const isReview = queryParameters.get('formBuilder') === 'review';

		if (this.state.dirty) {
			this.props.element.dirty = true;
		}
		const this_checked = this.props.element.hasOwnProperty('required')
			? this.props.element.required
			: false;
		const is_currency =
			this.props.element.description === 'currency' ? this.props.element.is_currency : false;
		const this_edit_only_link = this.props.element.custom_options?.includes('editOnlyLink')
			? true
			: false;
		const this_checked_bold = this.props.element.hasOwnProperty('bold')
			? this.props.element.bold
			: false;
		const this_hide_time_period = this.props.element.custom_options?.includes('hideTimePeriod')
			? true
			: false;
		const this_hide_Icon = this.props.element.custom_options?.includes('hideIcon') ? true : false;

		const this_read_only = this.props.element.hasOwnProperty('read_only')
			? this.props.element.read_only
			: false;
		const this_default_today = this.props.element.hasOwnProperty('defaultToday')
			? this.props.element.defaultToday
			: false;
		const this_show_time_select = this.props.element.hasOwnProperty('showTimeSelect')
			? this.props.element.showTimeSelect
			: false;
		const this_show_time_select_only = this.props.element.hasOwnProperty('showTimeSelectOnly')
			? this.props.element.showTimeSelectOnly
			: false;
		const this_show_time_input = this.props.element.hasOwnProperty('showTimeInput')
			? this.props.element.showTimeInput
			: false;
		const this_checked_inline = this.props.element.hasOwnProperty('inline')
			? this.props.element.inline
			: false;
		const this_checked_center = this.props.element.hasOwnProperty('center')
			? this.props.element.center
			: false;
		const this_checked_page_break = this.props.element.hasOwnProperty('pageBreakBefore')
			? this.props.element.pageBreakBefore
			: false;
		const this_checked_alternate_form = this.props.element.hasOwnProperty('alternateForm')
			? this.props.element.alternateForm
			: false;

		const {
			can_have_page_break_before,
			can_have_alternate_form,
			can_have_display_horizontal,
			can_have_option_correct,
			can_have_option_value
		} = this.props.element;

		const this_files = this.props.files.length ? this.props.files : [];
		if (this_files.length < 1 || (this_files.length > 0 && this_files[0].id !== '')) {
			this_files.unshift({ id: '', file_name: '' });
		}

		const isReadyToPublish = this.props.isReadyToPublish;
		const uniqueIdentifier =
			this.props.element.unique_identifier === undefined
				? ' [tmp-' + this.props.element.temp_order + ']'
				: ' [ff-' + this.props.element.unique_identifier + ']';

		return (
			<div data-testid={'formbuilder-editpanel'}>
				<div className="clearfix editpanel-title-container">
					<h4 className="float-left editpanel-title">
						<FormElementIcon element={this.props.element?.text} />
						{this.props.element.text}
						{uniqueIdentifier}
					</h4>
					<IconButton
						onClick={this.props.manualEditModeOff}
						className="float-right mt-0 dismiss-edit"
						aria-label="delete"
					>
						<CloseIcon color="primary" />
					</IconButton>
				</div>

				{/* --------------------------- */}
				{/* ELEMENT PARTS */}
				{/* --------------------------- */}
				{/* ARM-415: element parts section will show up on all elements after save. Because we are saving an empty label to the db. For elements that we want to show before save add a label value into ToolboxSchema. For elements we don't want this screen after save we check if label is null, as that is what the api returns */}
				{this.props.element.hasOwnProperty('label') && this.props.element.label !== null && (
					<div className="editPanel-section-container">
						<p data-testid={'printOptions-label'} className="editPanel-section-container-title">
							ELEMENT PARTS
						</p>
						<div className="editPanel-section-inner hasVerticalSideBar">
							{/* --------------------------- */}
							{/* Label */}
							{/* --------------------------- */}
							<div style={{ display: 'flex ', gap: '25px' }}>
								<MyTextField
									sx={{ width: '80%' }}
									data-testid={'display-label-editor'}
									data={{
										label: 'Label'
									}}
									color="warning"
									value={this.props.element.label}
									defaultValue={this.props.element.label}
									onBlur={this.updateElement.bind(this)}
									onChange={(e) => this.customEditElementProp('label', e?.target?.value)}
								/>
								<MyTextField
									disabled={true}
									sx={{
										width: '20%'
									}}
									isElementId={true}
									data-testid={'display-label-editor'}
									data={{
										label: 'Element ID'
									}}
									color="warning"
									className="ElementIdDisable"
									defaultValue={`${
										this.props.element.unique_identifier
											? `[ff-${this.props.element.unique_identifier}]`
											: `[tmp-${this.props.element.temp_order}]`
									}`}
									inputProps={{
										style: { backgroundColor: '#FFFFFF' }
									}}
									onBlur={this.updateElement.bind(this)}
								/>
							</div>

							{/* -------------- */}
							{/* File Attachment Section */}
							{/* -------------- */}
							{this.props.element?.key === 'MUI_FileAttachment' && (
								<>
									<FileAttachment_Upload
										label="Name"
										id="component-outlined2"
										{...this.props}
										onSave={(e) => this.customEditElementProp('src', e)}
										onDelete={() => this.customEditElementProp('src', '')}
										onBlur={this.updateElement.bind(this)}
									/>
								</>
							)}
							{/* --------------------------- */}
							{/* Required */}
							{/* --------------------------- */}
							{this.props.element?.key !== 'MUI_GroupingStart' &&
								this.props.element?.key !== 'MUI_FileAttachment' &&
								this.props.element?.key !== 'MUI_Calculator' &&
								this.props.element?.element !== 'Image' && (
									<>
										<div>
											<FormControlLabel
												data-testid={'isRequired-label'}
												disabled={isReadyToPublish}
												control={
													<Checkbox
														data-testid={'isRequired-input'}
														id="is-required"
														checked={this_checked || this.props.element.required}
														onChange={this.editElementProp.bind(this, 'required', 'checked')}
														name="gilad"
													/>
												}
												label="Required"
											/>
											{isReadyToPublish && (
												<FormHelperText>
													Fields cannot be made required once a form has been finalized
												</FormHelperText>
											)}
										</div>
										{this.props.element?.key === 'MUI_NumberInput' && (
											<FormControlLabel
												data-testid={'isCurrency-label'}
												control={
													<Checkbox
														data-testid={'isCurrency-input'}
														id="is-currency"
														checked={is_currency || this.props.element.description === 'currency'}
														onBlur={this.updateElement.bind(this)}
														// onChange={(e) => this.editElementProp.bind(this, 'description', e?.target?.checked ? 'currency' : '')}
														onChange={(e) =>
															this.customEditElementProp(
																'description',
																e?.target?.checked ? 'currency' : ''
															)
														}
														name="currency"
													/>
												}
												label="Is Currency"
											/>
										)}
									</>
								)}
							{(this.props.element?.key === 'MUI_TextArea' ||
								this.props.element?.key === 'MUI_Input' ||
								this.props.element?.key === 'MUI_Email') && (
								<MyTextField
									sx={{
										paddingBottom: '10px',
										width: '200px',
										'.MuiFormHelperText-root': { fontSize: '0.68rem' }
									}}
									type={'number'}
									data-testid={'field-limit-input'}
									data={{ label: 'Character Limit', required: false }}
									className="character-limit-input"
									helperText="Limits field characters if set."
									color="warning"
									defaultValue={
										this.props.element.limit_value === 0 ? '' : this.props.element.limit_value
									}
									onBlur={this.updateElement.bind(this)}
									onChange={(e) =>
										this.customEditElementProp(
											'limit_value',
											e?.target?.value === '' ? 0 : e?.target?.value
										)
									}
								/>
							)}
							{/* --------------------------- */}
							{/* Inline Help */}
							{/* --------------------------- */}
							{this.props.element.hasOwnProperty('label') && (
								<MyTextField
									sx={{ width: '100%' }}
									data-testid={'display-label-editor'}
									data={{
										label: 'Inline help'
									}}
									color="warning"
									defaultValue={this.props.element.help_message}
									onBlur={this.updateElement.bind(this)}
									onChange={(e) => this.customEditElementProp('help_message', e?.target?.value)}
								/>
							)}
						</div>
					</div>
				)}

				{/* --------------------- */}
				{/* ELEMENT PARTS Section */}
				{/* --------------------- */}
				<div className="editPanel-section-container">
					{this.props.element.hasOwnProperty('label') && <></>}

					{this.props.element?.key === 'MUI_LinkInput' && (
						<>
							<div className="d-flex flex-column">
								<MyTextField
									sx={{ width: '100%' }}
									data-testid={'link-input-input'}
									data={{ label: 'Link Label' }}
									color="warning"
									defaultValue={this.props.element.second_label}
									onBlur={this.updateElement.bind(this)}
									onChange={(e) => this.customEditElementProp('second_label', e?.target?.value)}
								/>
							</div>
						</>
					)}
					{isReview &&
						[
							'MUI_NumberInput',
							'MUI_Checkbox',
							'MUI_RadioButton',
							'StatePicker',
							'MUI_Dropdown'
						].includes(this.props.element.key) && (
							<FormControlLabel
								data-testid={'isTotalScore-label'}
								control={
									<Checkbox
										data-testid={'isTotalScore-input'}
										id="is-total-score"
										checked={this.props.element.description === 'total_score'}
										onBlur={this.updateElement.bind(this)}
										onChange={(e) =>
											this.customEditElementProp(
												'description',
												e?.target?.checked ? 'total_score' : ''
											)
										}
										name="total_score"
									/>
								}
								label="Include in Total Score"
							/>
						)}
					{this.props.element.hasOwnProperty('content') && (
						<div className="form-group" onBlur={this.updateElement.bind(this)}>
							<label className="control-label">
								<IntlMessages id="text-to-display" />:
							</label>
							<ReactQuill
								id="gl-editor-main"
								value={this.props.element.content}
								onChange={this.instructionDataHandler}
								theme={RTE_SETTINGS.theme}
								modules={RTE_SETTINGS.modules}
								formats={RTE_SETTINGS.formats}
							/>
						</div>
					)}

					{this.props.element.hasOwnProperty('href') && (
						<div className="form-group">
							<TextAreaAutosize
								type="text"
								className="form-control"
								defaultValue={this.props.element.href}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'href', 'value')}
							/>
						</div>
					)}

					{this.props.element.hasOwnProperty('file_path') && (
						<div className="form-group">
							<label className="control-label" htmlFor="fileSelect">
								<IntlMessages id="choose-file" />:
							</label>
							<select
								id="fileSelect"
								className="form-control"
								defaultValue={this.props.element.file_path}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'file_path', 'value')}
							>
								{this_files.map((file) => {
									const this_key = `file_${file.id}`;
									return (
										<option value={file.id} key={this_key}>
											{file.file_name}
										</option>
									);
								})}
							</select>
						</div>
					)}

					{this.props.element?.key === 'MUI_LinkInput' && (
						<>
							<FormControlLabel
								data-testid={'hideIcon-label'}
								control={
									<Checkbox
										data-testid={'hideIcon-input'}
										id="hide-icon"
										checked={this_hide_Icon}
										onChange={() => {
											let customOptions = this.props.element.custom_options;
											customOptions.includes('hideIcon')
												? customOptions.splice(customOptions.indexOf('hideIcon'), 1)
												: customOptions.push('hideIcon');
											this.customEditElementProp('custom_options', customOptions);
										}}
										name="hideIcon"
									/>
								}
								label="Hide Icon"
							/>
							<FormControlLabel
								control={
									<Checkbox
										data-testid={'editOnlyLink-input'}
										id="editOnlyLink-checkbox"
										checked={this_edit_only_link}
										onChange={() => {
											let customOptions = this.props.element.custom_options;
											customOptions.includes('editOnlyLink')
												? customOptions.splice(customOptions.indexOf('editOnlyLink'), 1)
												: customOptions.push('editOnlyLink');
											this.customEditElementProp('custom_options', customOptions);
										}}
										name="editOnlyLink"
									/>
								}
								label="Edit Only Link"
							/>
						</>
					)}

					{this.props.element?.key === 'MUI_StartEndDate' && (
						<FormControlLabel
							control={
								<Checkbox
									data-testid={'time-period-input'}
									id="hide-time-period"
									checked={this_hide_time_period}
									onChange={() => {
										let customOptions = this.props.element.custom_options;
										customOptions.includes('hideTimePeriod')
											? customOptions.splice(customOptions.indexOf('hideTimePeriod'), 1)
											: customOptions.push('hideTimePeriod');
										this.customEditElementProp('custom_options', customOptions);
									}}
									name="hideTotalTime"
								/>
							}
							label="Hide Total/Remaining Time"
						/>
					)}

					{this.props.element.hasOwnProperty('defaultToday') && (
						<div className="custom-control custom-checkbox">
							<input
								data-testid={'defaultToday-input'}
								id="is-default-to-today"
								className="custom-control-input"
								type="checkbox"
								checked={this_default_today}
								value={true}
								onChange={this.editElementProp.bind(this, 'defaultToday', 'checked')}
							/>
							<label
								data-testid={'defaultToday-label'}
								className="custom-control-label"
								htmlFor="is-default-to-today"
							>
								<IntlMessages id="default-to-today" />?
							</label>
						</div>
					)}
					{this.props.element.hasOwnProperty('showTimeSelect') && (
						<FormControlLabel
							control={
								<Checkbox
									data-testid={'showTimeSelect-input'}
									id="show-time-select"
									checked={this_show_time_select}
									onChange={this.editElementProp.bind(this, 'showTimeSelect', 'checked')}
									name="showTimeSelect"
								/>
							}
							label="Show Time Select"
						/>
					)}
					{this_show_time_select &&
						(this.props.element.hasOwnProperty('showTimeSelectOnly') ||
							this.state.element.key === 'MUI_DatePicker') && (
							<FormControlLabel
								control={
									<Checkbox
										data-testid={'showTimeSelectOnly-input'}
										id="show-time-select-only"
										checked={this_show_time_select_only}
										onChange={this.editElementProp.bind(this, 'showTimeSelectOnly', 'checked')}
										name="showTimeSelectOnly"
									/>
								}
								label="Show Time Select Only"
							/>
						)}
					{this.props.element.hasOwnProperty('showTimeInput') && (
						<FormControlLabel
							control={
								<Checkbox
									data-testid={'showTimeInput-input'}
									id="show-time-input"
									checked={this_show_time_input}
									onChange={this.editElementProp.bind(this, 'showTimeInput', 'checked')}
									name="showTimeInput"
								/>
							}
							label="Show Time Input"
						/>
					)}
					{(this.state.element.element === 'RadioButtons' ||
						this.state.element.element === 'Checkboxes' ||
						this.state.element.key === 'MUI_Checkbox') &&
						can_have_display_horizontal && (
							<FormControlLabel
								control={
									<Checkbox
										data-testid={'horizontalDisplay-input'}
										id="display-horizontal"
										checked={this_checked_inline}
										onChange={this.editElementProp.bind(this, 'inline', 'checked')}
										name="displayHorizontal"
									/>
								}
								label="Display Horizontal"
							/>
						)}

					{this.props.element?.element === 'Image' && (
						<MyTextField
							sx={{ width: '100%' }}
							data-testid={'src-input'}
							id="srcInput"
							data={{ label: 'Link To' }}
							color="warning"
							defaultValue={this.props.element.src}
							onBlur={this.updateElement.bind(this)}
							onChange={this.editElementProp.bind(this, 'src', 'value')}
						/>
					)}

					{this.props.element?.element === 'Image' && (
						<div>
							<div className="form-group">
								<FormControlLabel
									control={
										<Checkbox
											data-testid={'centerImage-input'}
											id="do-center"
											checked={this_checked_center}
											onChange={this.editElementProp.bind(this, 'center', 'checked')}
											name="centerImage"
										/>
									}
									label="Center"
								/>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<MyTextField
										sx={{ width: '100%' }}
										data-testid={'elementWidth-input'}
										id="elementWidth"
										data={{ label: 'Width' }}
										type={'number'}
										color="warning"
										defaultValue={this.props.element.width}
										onBlur={this.updateElement.bind(this)}
										onChange={this.editElementProp.bind(this, 'width', 'value')}
									/>
								</div>
								<div className="col-sm-6">
									<MyTextField
										sx={{ width: '100%' }}
										data-testid={'elementHeight-input'}
										id="elementHeight"
										data={{ label: 'Height' }}
										type={'number'}
										color="warning"
										defaultValue={this.props.element.height}
										onBlur={this.updateElement.bind(this)}
										onChange={this.editElementProp.bind(this, 'height', 'value')}
									/>
								</div>
							</div>
						</div>
					)}
					{this.state.element.element === 'FileUpload' && (
						<div>
							<div className="form-group">
								<label data-testid={'fileType-label'} className="control-label" htmlFor="fileType">
									<IntlMessages id="choose-file-type" />:
								</label>
								<select
									data-testid={'fileType-input'}
									id="fileType"
									className="form-control"
									onBlur={this.updateElement.bind(this)}
									onChange={this.editElementProp.bind(this, 'fileType', 'value')}
								>
									{[
										{
											type: 'image, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, video/mp4,video/x-m4v,video/*',
											typeName: 'All File Type'
										},
										{ type: 'image', typeName: 'Image' },
										{ type: 'application/pdf', typeName: 'PDF' },
										{
											type: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
											typeName: 'Word'
										},
										{
											type: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
											typeName: 'Excel'
										},
										{
											type: 'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
											typeName: 'Powerpoint'
										},
										{
											type: 'video/mp4,video/x-m4v,video/*',
											typeName: 'Videos'
										}
									].map((file, index) => (
										<option value={file.type} key={index}>
											{file.typeName}
										</option>
									))}
								</select>
							</div>
						</div>
					)}
					{this.state.element.element === 'Signature' && this.props.element.read_only ? (
						<div className="form-group">
							<label
								data-testid={'variableKey-label'}
								className="control-label"
								htmlFor="variableKey"
							>
								<IntlMessages id="variable-key" />:
							</label>
							<input
								data-testid={'variableKey-input'}
								id="variableKey"
								type="text"
								className="form-control"
								defaultValue={this.props.element.variableKey}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'variableKey', 'value')}
							/>
							<p className="help-block">
								<IntlMessages id="variable-key-desc" />.
							</p>
						</div>
					) : (
						<div />
					)}

					{this.props.element.hasOwnProperty('step') && (
						<div className="form-group">
							<div className="form-group-range">
								<label
									data-testid={'rangeStep-label'}
									className="control-label"
									htmlFor="rangeStep"
								>
									<IntlMessages id="step" />
								</label>
								<input
									data-testid={'rangeStep-input'}
									id="rangeStep"
									type="number"
									className="form-control"
									defaultValue={this.props.element.step}
									onBlur={this.updateElement.bind(this)}
									onChange={this.editElementProp.bind(this, 'step', 'value')}
								/>
							</div>
						</div>
					)}
					{this.props.element.hasOwnProperty('min_value') &&
						!this.props.element?.custom_options?.includes('min_and_max') && (
							<div className="form-group">
								<div className="form-group-range">
									<label
										data-testid={'rangeMin-label'}
										className="control-label"
										htmlFor="rangeMin"
									>
										<IntlMessages id="min" />
									</label>
									<input
										data-testid={'rangeMin-input'}
										id="rangeMin"
										type="number"
										className="form-control"
										defaultValue={this.props.element.min_value}
										onBlur={this.updateElement.bind(this)}
										onChange={this.editElementProp.bind(this, 'min_value', 'value')}
									/>
									<input
										data-testid={'rangeMinLabel-input'}
										type="text"
										className="form-control"
										defaultValue={this.props.element.min_label}
										onBlur={this.updateElement.bind(this)}
										onChange={this.editElementProp.bind(this, 'min_label', 'value')}
									/>
								</div>
							</div>
						)}
					{this.props.element.hasOwnProperty('max_value') &&
						!this.props.element?.custom_options?.includes('min_and_max') && (
							<div className="form-group">
								<div className="form-group-range">
									<label
										data-testid={'rangeMax-label'}
										className="control-label"
										htmlFor="rangeMax"
									>
										<IntlMessages id="max" />
									</label>
									<input
										data-testid={'rangeMax-input'}
										id="rangeMax"
										type="number"
										className="form-control"
										defaultValue={this.props.element.max_value}
										onBlur={this.updateElement.bind(this)}
										onChange={this.editElementProp.bind(this, 'max_value', 'value')}
									/>
									<input
										data-testid={'rangeMaxLabel-input'}
										type="text"
										className="form-control"
										defaultValue={this.props.element.max_label}
										onBlur={this.updateElement.bind(this)}
										onChange={this.editElementProp.bind(this, 'max_label', 'value')}
									/>
								</div>
							</div>
						)}
					{isReview && this.props.element?.custom_options?.includes('min_and_max') && (
						<div className="form-group">
							<div className="form-group-range d-flex gap-3" style={{ gap: '30px;' }}>
								<div className="form-group-range">
									<MyTextField
										type={'number'}
										data-testid={'rangeMin-input'}
										id="rangeMin"
										data={{
											required: `${this.props.element.description === 'total_score'}`,
											label: 'Minimum Value'
										}}
										color="warning"
										style={{ width: '100%', borderWidth: '0px!important' }}
										defaultValue={this.props.element.min_value}
										onBlur={this.updateElement.bind(this)}
										onChange={this.editElementProp.bind(this, 'min_value', 'value')}
									/>
								</div>
								<div className="form-group-range">
									<MyTextField
										type={'number'}
										sx={{ width: '100%' }}
										data-testid={'rangeMax-input'}
										id="rangeMax"
										data={{
											label: 'Maximum Value',
											required: `${this.props.element.description === 'total_score'}`
										}}
										color="warning"
										defaultValue={this.props.element.max_value}
										onBlur={this.updateElement.bind(this)}
										onChange={this.editElementProp.bind(this, 'max_value', 'value')}
									/>
								</div>
							</div>
						</div>
					)}

					{this.props.element?.key === 'MUI_DatePicker' && (
						<div>
							<label data-testid={'dateRange-label'} className="control-label MarginBottom20">
								<IntlMessages id="Date Range:" />
							</label>
							<div className="d-flex gap-3 justify-center align-items-center">
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<FormControl fullWidth>
										<StyledWrapper.CustomLabel variant="outlined">
											Min Date
										</StyledWrapper.CustomLabel>
										<StyledWrapper.DatePickerMargin
											data-testid={'minDate-input'}
											disabled={false}
											read_only={false}
											defaultValue={this.props.element.min_date || null}
											value={this.props.element.min_date || null}
											onBlur={this.updateElement.bind(this)}
											color="warning"
											onChange={(e) => this.customEditElementProp('min_date', e?.$d)}
											renderInput={(params) => <TextField color="primary" {...params} />}
										/>
									</FormControl>
								</LocalizationProvider>
								<div>
									<p style={{ margin: '0px' }}> to </p>
								</div>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<FormControl fullWidth>
										<StyledWrapper.CustomLabel variant="outlined">
											Max Date
										</StyledWrapper.CustomLabel>
										<StyledWrapper.DatePickerMargin
											data-testid={'maxDate-input'}
											disabled={false}
											read_only={false}
											defaultValue={this.props.element.max_date || null}
											value={this.props.element.max_date || null}
											onBlur={this.updateElement.bind(this)}
											color="warning"
											onChange={(e) => this.customEditElementProp('max_date', e?.$d)}
											renderInput={(params) => <TextField color="primary" {...params} />}
										/>
									</FormControl>
								</LocalizationProvider>
								<IconButton
									aria-label="delete"
									size="small"
									color="error"
									onClick={() => {
										this.customEditElementProp('min_date', null);
										this.customEditElementProp('max_date', null);
									}}
								>
									<ClearIcon fontSize="small" />
								</IconButton>
							</div>
						</div>
					)}
					{this.props.element.hasOwnProperty('default_value') && (
						<div className="form-group">
							<div className="form-group-range">
								<label
									data-testid={'defaultSelected-label'}
									className="control-label"
									htmlFor="defaultSelected"
								>
									<IntlMessages id="default-selected" />
								</label>
								<input
									data-testid={'defaultSelected-input'}
									id="defaultSelected"
									type="number"
									className="form-control"
									defaultValue={this.props.element.default_value}
									onBlur={this.updateElement.bind(this)}
									onChange={this.editElementProp.bind(this, 'default_value', 'value')}
								/>
							</div>
						</div>
					)}
					{this.props.element.showDescription && (
						<div className="form-group">
							<label
								data-testid={'description-label'}
								className="control-label"
								htmlFor="questionDescription"
							>
								<IntlMessages id="description" />
							</label>
							<TextAreaAutosize
								data-testid={'description-input'}
								type="text"
								className="form-control"
								id="questionDescription"
								defaultValue={this.props.element.description}
								onBlur={this.updateElement.bind(this)}
								onChange={this.editElementProp.bind(this, 'description', 'value')}
							/>
						</div>
					)}
					{this.props.showCorrectColumn &&
						this.props.element.canHaveAnswer &&
						!this.props.element.hasOwnProperty('options') && (
							<div className="form-group">
								<label
									data-testid={'correctAnswer-label'}
									className="control-label"
									htmlFor="correctAnswer"
								>
									<IntlMessages id="correct-answer" />
								</label>
								<input
									data-testid={'correctAnswer-input'}
									id="correctAnswer"
									type="text"
									className="form-control"
									defaultValue={this.props.element.correct}
									onBlur={this.updateElement.bind(this)}
									onChange={this.editElementProp.bind(this, 'correct', 'value')}
								/>
							</div>
						)}
					{this.props.element.hasOwnProperty('options') && (
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography data-testid={'options-label'}>Options:</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<DynamicOptionList
									isReview={isReview}
									showCorrectColumn={this.props.showCorrectColumn}
									can_have_option_correct={can_have_option_correct}
									can_have_option_value={can_have_option_value}
									data={this.props.preview.state.data}
									updateElement={this.props.updateElement}
									preview={this.props.preview}
									element={this.props.element}
									key={this.props.element.options.length}
									include_in_total_score={this.props.element.description === 'total_score'}
								/>
							</AccordionDetails>
						</Accordion>
					)}
				</div>
				{/* -------------- */}
				{/* Calculation Formula Section */}
				{/* -------------- */}
				{this.props.element?.key === 'MUI_Calculator' && (
					<div className="editPanel-section-container">
						<p className="editPanel-section-container-title">FORMULA CREATION</p>
						<div className="editPanel-section-inner hasVerticalSideBar">
							<CalculatorLogic
								{...this.props}
								update={(value) => {
									this.customEditElementProp('calc_formula', value);
								}}
							/>
						</div>
					</div>
				)}
				{/* --------------------- */}
				{/* PRINT OPTIONS Section */}
				{/* --------------------- */}
				{(can_have_page_break_before || can_have_alternate_form) && (
					<div className="editPanel-section-container">
						<p className="editPanel-section-container-title">PRINT OPTIONS</p>
						<div className="editPanel-section-inner hasVerticalSideBar">
							<div className="editPanel-inline-row">
								{can_have_page_break_before && (
									<div className="form-group">
										<FormControlLabel
											control={
												<Checkbox
													data-testid={'pageBreak-input'}
													id="page-break-before-element"
													checked={this_checked_page_break}
													onChange={this.editElementProp.bind(this, 'pageBreakBefore', 'checked')}
													name="gilad"
												/>
											}
											label="Page Break Before Element"
											data-testid={'pageBreak-label'}
										/>
									</div>
								)}
								{can_have_alternate_form && (
									<div className="form-group">
										<FormControlLabel
											control={
												<Checkbox
													data-testid={'altSignature-input'}
													id="display-on-alternate"
													checked={this_checked_alternate_form}
													onChange={this.editElementProp.bind(this, 'alternateForm', 'checked')}
													name="gilad"
												/>
											}
											label="Display on alternate/signature Page"
											data-testid={'altSignature-label'}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				)}

				{/* --------------------------- */}
				{/* FORM BUSINESS LOGIC Section */}
				{/* --------------------------- */}
				{this.props.element?.key !== 'MUI_Calculator' &&
					this.props.element?.group_name !== 'Layout' &&
					this.props.element?.is_testing !== true && (
						<div className="editPanel-section-container">
							<p data-testid={'printOptions-label'} className="editPanel-section-container-title">
								FORM BUSINESS LOGIC{' '}
								<SettingsSuggestIcon className="mt-5-negative" color="action" fontSize="small" />
							</p>
							<div className="editPanel-section-inner hasVerticalSideBar">
								<CustomLogic
									{...this.props}
									update={(value) => {
										this.customEditElementProp('dependency', value);
									}}
								/>
							</div>
						</div>
					)}
			</div>
		);
	}
}
FormElementsEdit.defaultProps = { className: 'edit-element-fields' };
