/**
 * <Preview />
 */

import React from 'react';
import update from 'immutability-helper';
import store from './stores/store';
import FormElementsEdit from './form-dynamic-edit';
import SortableFormElements from './sortable-form-elements';
import CustomDragLayer from './form-elements/component-drag-layer';

import IconButton from '@mui/material/IconButton';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PasteIcon from '../../assets/customIcons/pasteIcon';

const { PlaceHolder } = SortableFormElements;

export default class Preview extends React.Component {
	state = {
		data: [],
		answer_data: {}
	};

	constructor(props) {
		super(props);

		const { onLoad, onPost } = props;
		store.setExternalHandler(onLoad, onPost);

		this.editForm = React.createRef();

		this.state = {
			data: props.data || [],
			answer_data: {},
			temp_order: props?.data?.length || 1
		};
		this.seq = 0;

		this._onUpdate = this._onChange.bind(this);
		this.getDataById = this.getDataById.bind(this);
		this.moveCard = this.moveCard.bind(this);
		this.insertCard = this.insertCard.bind(this);
		this.setAsChild = this.setAsChild.bind(this);
		this.removeChild = this.removeChild.bind(this);
		this._onDestroy = this._onDestroy.bind(this);
	}

	componentDidMount() {
		const { data, url, saveUrl, saveAlways } = this.props;
		store.subscribe((state) => this._onUpdate(state.data));
		store.dispatch('load', { loadUrl: url, saveUrl, data: data || [], saveAlways });
		document.addEventListener('mousedown', this.editModeOff);
		window.addEventListener('dragend', this.handleDragEnd);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.editModeOff);
		window.removeEventListener('dragend', this.handleDragEnd);
	}

	handleDragEnd = () => {
		const { data } = this.state;
		store.dispatch('deletePlaceholders', data);
	};

	// editModeOff = (e) => {
	// 	if (this.editForm.current && !this.editForm.current.contains(e.target)) {
	// 		this.manualEditModeOff();
	// 	}
	// };

	manualEditModeOff = () => {
		const { editElement } = this.props;
		if (editElement && editElement.dirty) {
			editElement.dirty = false;
			this.updateElement(editElement);
		}
		this.props.manualEditModeOff();
	};

	_setValue(text) {
		return text.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
	}

	updateElement(element) {
		const { data } = this.state;
		let found = false;
		let indexToUpdate = -1;
		// Find the index of the element with the same field_id
		if ('id' in element) {
			indexToUpdate = data.findIndex((item) => item.id === element.id);
		} else if ('field_id' in element) {
			indexToUpdate = data.findIndex((item) => item.field_id === element.field_id);
		}
		// If the index is found, replace the object at that index with the new element
		if (indexToUpdate !== -1) {
			data[indexToUpdate] = element;
			found = true;
		}

		if (found) {
			this.seq = this.seq > 100000 ? 0 : this.seq + 1;
			store.dispatch('updateOrder', data);
		}
	}

	_onChange(data) {
		const answer_data = {};

		data.forEach((item) => {
			if (item && item.read_only && this.props.variables[item.variableKey]) {
				answer_data[item.field_name] = this.props.variables[item.variableKey];
			}
		});

		this.setState({
			data,
			answer_data
		});
	}

	_onDestroy(item, removePlaceholders) {
		const { data } = this.state;

		if (removePlaceholders) {
			// this.setState(newData);
			store.dispatch('deletePlaceholders', data);
		} else {
			if (item.child_items) {
				item.child_items.forEach((x) => {
					const child = this.getDataById(x);
					if (child) {
						store.dispatch('delete', child);
					}
				});
			}
			store.dispatch('delete', item);
		}
	}

	getDataById(id) {
		const { data } = this.state;
		return data.find((x) => {
			const itemID = x?.field_id || x?.id;
			return x && itemID?.toLowerCase() === id?.toLowerCase();
		});
	}

	swapChildren(data, item, child, col) {
		if (
			child.col !== undefined &&
			item.field_id?.toLowerCase() !== child.parent_id?.toLowerCase()
		) {
			return false;
		}
		if (!(child.col !== undefined && child.col !== col && item.child_items[col])) {
			// No child was assigned yet in both source and target.

			return false;
		}
		const oldId = item.child_items[col];
		const oldItem = this.getDataById(oldId);

		const oldCol = child.col;
		// eslint-disable-next-line no-param-reassign
		item.child_items[oldCol] = oldId;
		oldItem.col = oldCol;
		// eslint-disable-next-line no-param-reassign
		item.child_items[col] = child.field_id;
		child.col = col;
		store.dispatch('updateOrder', data);
		return true;
	}

	setAsChild(item, child, col) {
		const { data, last_created, temp_order } = this.state;

		let updated_temp_order = temp_order || 0;
		if (child?.field_name !== last_created) {
			updated_temp_order += 1;
		}
		child['temp_order'] = updated_temp_order;
		const finalData = { ...this.state, temp_order: updated_temp_order };
		this.setState(finalData);

		if (this.swapChildren(data, item, child, col)) {
			return;
		}

		child = { ...child, field_id: child?.id || child?.field_id, id: child?.id || child?.field_id };
		item = { ...item, field_id: item?.id || item?.field_id, id: item?.id || item?.field_id };

		const oldParent = this.getDataById(child.parent_id);

		const oldCol = oldParent?.child_items?.findIndex(
			(x) => x?.toLowerCase() === child.field_id?.toLowerCase()
		);

		// eslint-disable-next-line no-param-reassign
		item.child_items[col] = child.id || child?.field_id;
		child.col = col;
		// eslint-disable-next-line no-param-reassign
		child.parent_id = item?.id || item?.field_id;

		// eslint-disable-next-line no-param-reassign
		child.parent_index = data.findIndex(
			(x) => x?.field_id?.toLowerCase() === item?.field_id?.toLowerCase()
		);
		if (oldParent) {
			oldParent.child_items[oldCol] = null;
		} else {
			const currentIndex = data?.findIndex((e) => {
				const currentID = e?.id || e?.field_id;
				const isSame = currentID?.toLowerCase() === child?.field_id?.toLowerCase();
				const isNotChild = !e?.parent_id;
				return isSame && isNotChild;
			});
			currentIndex >= 0 && data.splice(currentIndex, 1);
		}

		let newData = data;
		let indexOfSameChild = newData?.findIndex(
			(e) => e?.field_id?.toLowerCase() === child?.field_id?.toLowerCase()
		);

		if (!this.getDataById(child.id)) {
			newData.push(child);
		} else {
			newData[indexOfSameChild] = child;
		}
		store.dispatch('updateOrder', newData);
	}

	removeChild(item, col) {
		const { data } = this.state;
		const oldId = item.child_items[col];
		const oldItem = this.getDataById(oldId);
		if (oldItem) {
			const newData = data.filter((x) => x !== oldItem);
			// eslint-disable-next-line no-param-reassign
			item.child_items[col] = null;
			// delete oldItem.parent_id;
			this.seq = this.seq > 100000 ? 0 : this.seq + 1;
			store.dispatch('updateOrder', newData);
			this.setState({ data: newData });
		}
	}

	restoreCard(item, id) {
		const { data } = this.state;
		const parent = this.getDataById(item.data.parent_id);
		const oldItem = this.getDataById(id);
		if (parent && oldItem) {
			const newIndex = data.indexOf(oldItem);
			const newData = [...data]; // data.filter(x => x !== oldItem);
			// eslint-disable-next-line no-param-reassign
			parent.child_items[oldItem.col] = null;
			delete oldItem.parent_id;
			// eslint-disable-next-line no-param-reassign
			delete item.setAsChild;
			// eslint-disable-next-line no-param-reassign
			delete item.parent_index;
			// eslint-disable-next-line no-param-reassign
			item.index = newIndex;
			this.seq = this.seq > 100000 ? 0 : this.seq + 1;
			store.dispatch('updateOrder', newData);
			this.setState({ data: newData });
		}
	}

	insertCard(item, hoverIndex, id) {
		const { data, last_created, temp_order } = this.state;
		if (id) {
			this.restoreCard(item, id);
		} else {
			data.splice(hoverIndex, 0, item);
			const newData = update(this.state, {
				data: {
					$splice: [[hoverIndex, 0, item]]
				}
			});

			let updated_temp_order = temp_order ? (data?.length ? data?.length + 1 : 0) : 0;
			if (item?.field_name !== last_created) {
				updated_temp_order += 1;
			}
			item['temp_order'] = updated_temp_order;

			let unique = [...new Set(newData.data)];
			const finalData = { ...this.state, data: unique, temp_order: updated_temp_order };
			this.setState(finalData);
			store.dispatch('updateOrder', finalData.data);
		}
	}

	moveCard(dragIndex, hoverIndex) {
		const { data } = this.state;
		const dragCard = data[dragIndex];

		if (dragCard !== undefined) {
			let newData = data;

			let b = newData[dragIndex];
			newData[dragIndex] = newData[hoverIndex];
			newData[hoverIndex] = b;

			const finalData = { ...this.state, data: newData };
			this.setState(finalData);
			store.dispatch('updateOrder', finalData.data);
		}
	}

	// eslint-disable-next-line no-unused-vars
	cardPlaceHolder() {
		console.log('test placeholder');
	}

	saveData(dragCard, dragIndex, hoverIndex) {
		const newData = update(this.state, {
			data: {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard]
				]
			}
		});
		let unique = [...new Set(newData.data)];
		const finalData = { ...this.state, data: unique };
		this.setState(finalData);
		store.dispatch('updateOrder', finalData.data);
	}

	getElement(item, index) {
		if (item.custom) {
			if (!item.component || typeof item.component !== 'function') {
				// eslint-disable-next-line no-param-reassign
				item.component = this.props.registry.get(item.key);
			}
		}

		const SortableFormElement = SortableFormElements[item.element];

		if (SortableFormElement === null) {
			return null;
		}

		return (
			<SortableFormElement
				id={item.id}
				seq={this.seq}
				index={index}
				moveCard={this.moveCard}
				insertCard={this.insertCard}
				mutable={false}
				parent={this.props.parent}
				editModeOn={this.props.editModeOn}
				isDraggable={true}
				key={item.id}
				sortData={item.id}
				data={item}
				getDataById={this.getDataById}
				setAsChild={this.setAsChild}
				removeChild={this.removeChild}
				_onDestroy={this._onDestroy}
			/>
		);
	}

	showEditForm() {
		const handleUpdateElement = (element) => this.updateElement(element);
		handleUpdateElement.bind(this);

		const formElementEditProps = {
			showCorrectColumn: this.props.showCorrectColumn,
			files: this.props.files,
			manualEditModeOff: this.manualEditModeOff,
			preview: this,
			element: this.props.editElement,
			updateElement: handleUpdateElement,
			isReadyToPublish: this.props.isReadyToPublish
		};

		return this.props.renderEditForm(formElementEditProps);
	}

	render() {
		let classes = this.props.className;
		if (this.props.editMode) {
			classes += ' is-editing';
		}
		const data = this.state.data.filter((x) => !!x && !x.parent_id);
		const items = data.map((item, index) => this.getElement(item, index));
		console.log(items, 'test3');

		return (
			<div
				data-testid={'formviewer-preview-container'}
				className={`${classes} ${this.props?.readOnly && 'disabled-section'}`}
			>
				{!this.props?.hideToolbox && (
					<div className="formbuilder-draggableArea-text">
						<p>Drag and drop form elements in this drop zone to build your form.</p>
						<div
							className={`formbuilder-draggableArea-businessLogic ${
								!this.props.hasBusinessLogic && 'disabled-text'
							}`}
						>
							{this.props.hasBusinessLogic ? (
								<p>This form contains business logic!</p>
							) : (
								<p>This form is not containing business logic at this time</p>
							)}
							<SettingsSuggestIcon color="action" />
						</div>
						<IconButton
							id="inner-form-paste-icon"
							onClick={() => {
								const pasteIcon = document.getElementById('formBuilder-paste-button');
								pasteIcon?.click();
							}}
							aria-label="paste"
						>
							<PasteIcon />
						</IconButton>
					</div>
				)}

				<div
					className="edit-form-background"
					style={{ display: this.props.editElement !== null ? 'flex' : 'none' }}
				>
					<div className="edit-form" ref={this.editForm}>
						{this.props.editElement !== null && this.showEditForm()}
					</div>
				</div>

				<div className="Sortable">{items}</div>
				<PlaceHolder
					id="form-place-holder"
					show={items.length === 0}
					index={items.length}
					moveCard={this.cardPlaceHolder}
					insertCard={this.insertCard}
				/>
				{/* <CustomDragLayer /> */}
			</div>
		);
	}
}
Preview.defaultProps = {
	showCorrectColumn: false,
	files: [],
	editMode: false,
	editElement: null,
	className: 'react-form-builder-preview float-left',
	renderEditForm: (props) => <FormElementsEdit {...props} />
};
