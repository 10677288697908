import { useState, useEffect, forwardRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import StyledWrapper from './style';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Tooltip from '@mui/material/Tooltip';
import { stripTextHTML } from '../utils';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

export const CustomLogic = forwardRef((props, _ref) => {
	const andOrRef = useRef(null);
	const unCtlNativeRelatedFieldRef = useRef(null);
	const unCtlNativeConditionRef = useRef(null);
	const unCtlNativeValueRef = useRef(null);
	const reduxFormSchema = useSelector((state) => state?.form?.formSchema);
	const [chosenField, setChosenField] = useState([]);
	const [alertBalloon, setAlertBalloon] = useState(false);
	const [currentDependacies, setCurrentDependancies] = useState(props?.element?.dependency);
	const [saveButton, setSaveButton] = useState([]);
	const [fields, setFields] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [values, setValue] = useState({
		field: '',
		condition: '',
		value: '',
		optionText: ' '
	});

	useEffect(() => {
		let allFields = [];
		reduxFormSchema.forEach((e) => {
			const filteredFields = e?.filter((x) => props?.element?.field_name != x?.field_name);
			allFields.push(...filteredFields);
		});

		setFields(allFields);
	}, [reduxFormSchema]);

	useEffect(() => {
		const currentField = fields.find((e) => {
			return e?.field_name === values?.field;
		});
		setChosenField(currentField);
		showSaveButton();
	}, [values]);

	function findMatchingDependencyIndex(dependency) {
		if (!currentDependacies) return -1;
		const lastEntry = currentDependacies[currentDependacies?.length - 1];
		const and_or = andOrRef?.current?.value;
		if (Array.isArray(lastEntry) && and_or === 'or') {
			const matchingIndex = lastEntry?.findIndex((item) => {
				if (typeof item === 'object') {
					return Object.keys(dependency).every((key) => item[key] === dependency[key]);
				}
				return false;
			});

			return matchingIndex;
		} else if (typeof lastEntry === 'object' && and_or === 'and') {
			// If the last entry is an object, search for any object that matches the dependency
			const matchingIndex = currentDependacies?.findIndex((item) => {
				if (typeof item === 'object') {
					return Object.keys(dependency).every((key) => item[key] === dependency[key]);
				}
				return false;
			});

			return matchingIndex;
		} else if (typeof lastEntry === 'object' && and_or === 'or') {
			return Object.keys(dependency).every((key) => lastEntry[key] === dependency[key]); // No match found
		} else {
			return -1; // No match found
		}
	}

	const resetValidationErrors = () => {
		if (
			unCtlNativeRelatedFieldRef &&
			unCtlNativeRelatedFieldRef.current &&
			unCtlNativeRelatedFieldRef.current.parentNode
		) {
			unCtlNativeRelatedFieldRef.current.parentNode.style.border = '0';
		}
		if (
			unCtlNativeConditionRef &&
			unCtlNativeConditionRef.current &&
			unCtlNativeConditionRef.current.parentNode
		) {
			unCtlNativeConditionRef.current.parentNode.style.border = '0';
		}
		if (
			unCtlNativeValueRef &&
			unCtlNativeValueRef.current &&
			unCtlNativeValueRef.current.parentNode
		) {
			unCtlNativeValueRef.current.parentNode.style.border = '0';
		}
	};

	const resetDefaultValues = () => {
		if (unCtlNativeValueRef && unCtlNativeValueRef.current) {
			if (unCtlNativeValueRef.current.getElementsByTagName('option').length > 0) {
				unCtlNativeValueRef.current.getElementsByTagName('option')[0].selected = 'selected';
			} else {
				const input = document.getElementById('uncontrolled-native-value');
				if (input) {
					input.value = 'unspecified';
				}
			}
		}
	};

	const showValidationError = (id) => {
		const input = document.getElementById(id);
		if (input && input.parentNode.childNodes.length === 2) {
			input.parentNode.style.border = '1px solid #D32F2F';
		}
	};

	const saveDependancy = (updatedValues = values) => {
		resetValidationErrors();

		let hasError = false;
		if (updatedValues.field === '' || updatedValues.field === 'unspecified') {
			hasError = true;
			showValidationError('uncontrolled-native-relatedField');
		}
		if (updatedValues.condition === '' || updatedValues.condition === 'unspecified') {
			hasError = true;
			showValidationError('uncontrolled-native-condition');
		}
		if (
			updatedValues.value === '' &&
			updatedValues.condition !== 'exists' &&
			updatedValues.condition !== 'does not exist'
		) {
			hasError = true;
			showValidationError('uncontrolled-native-value');
		} else if (updatedValues.value === 'unspecified') {
			hasError = true;
			showValidationError('uncontrolled-native-value');
		}

		if (hasError) {
			setAlertBalloon({ isOpen: true, message: `Validation error!` });
			setTimeout(() => {
				setAlertBalloon(false);
			}, 1000);
		} else {
			const alreadyExists = findMatchingDependencyIndex(updatedValues);
			if (alreadyExists === -1 || alreadyExists === false) {
				const and_or = andOrRef?.current?.value;
				let newDependancies = Array.isArray(currentDependacies)
					? currentDependacies
					: [currentDependacies];

				if (and_or === 'or') {
					const lastEntry = newDependancies[newDependancies.length - 1];
					if (Array.isArray(lastEntry)) {
						lastEntry.push(updatedValues);
					} else if (typeof lastEntry === 'object') {
						newDependancies.pop();
						newDependancies.push([lastEntry, updatedValues]);
					}
				} else {
					newDependancies.push(updatedValues);
				}
				newDependancies = newDependancies.filter((item) => item !== undefined && item !== null);
				setCurrentDependancies(newDependancies);
				props.element.dependency = newDependancies;
				props.update(newDependancies);
				resetDefaultValues();
			} else {
				setAlertBalloon({ isOpen: true, message: `Dependency Already Exists!` });
				setTimeout(() => {
					setAlertBalloon(false);
				}, 1000);
			}
		}
	};

	const showSaveButton = () => {
		const condition1 = values.condition === 'exists';
		const condition2 = values.condition === 'does not exist';
		const condition3 = values.value !== '';

		setSaveButton(condition1 || condition2 || condition3);
	};

	const deleteDependency = (outerIndex, innerIndex) => {
		let newDependancies = Array.isArray(currentDependacies)
			? currentDependacies
			: [currentDependacies];

		if (outerIndex >= 0 && outerIndex < newDependancies.length) {
			const outerItem = newDependancies[outerIndex];

			if (Array.isArray(outerItem)) {
				// If the outer item is an array, remove the innerIndex from it
				if (innerIndex >= 0 && innerIndex < outerItem.length) {
					outerItem.splice(innerIndex, 1);
				}
				// Array with one element causes an issue so it would be converted to the  object
				if (outerItem.length === 1) {
					newDependancies[outerIndex] = outerItem[0];
				}
			} else if (typeof outerItem === 'object') {
				// If the outer item is an object, remove it from newDependancies
				newDependancies.splice(outerIndex, 1);
			}
		}

		props.element.dependency = newDependancies;
		props.update(newDependancies);
	};

	const showCurrentDependancy = (dependency, outerIndex, innerIndex) => {
		const label = fields?.find((e) => e?.field_name === dependency?.field);
		const formattedLabel = stripTextHTML(label?.label);
		let value =
			dependency?.condition !== 'exists' && dependency?.condition !== 'does not exist'
				? dependency?.value
				: '';

		value =
			dependency?.optionText && dependency?.optionText !== ' ' ? dependency?.optionText : value;

		return (
			dependency && (
				<StyledWrapper.CurrentDependanciesContainer>
					<StyledWrapper.CurrentDependancies>
						<div className={'block-75'}>
							<StyledWrapper.dependancy data-testid={'conditionalLogic-currendDependancy'}>
								{formattedLabel} {dependency?.condition} {value}
							</StyledWrapper.dependancy>
						</div>
						<div className={'block-25'}>
							<Button
								data-testid={'conditionalLogic-currendDependancy-clear'}
								aria-label="delete"
								variant="outlined"
								onClick={() => deleteDependency(outerIndex, innerIndex)}
								startIcon={
									<DoNotDisturbOnIcon
										color="error"
										fontSize="small"
										data-testid={'conditionalLogic-currendDependancy-clear'}
									/>
								}
								sx={{ backgroundColor: '#fff' }}
							>
								REMOVE
							</Button>
						</div>
					</StyledWrapper.CurrentDependancies>
				</StyledWrapper.CurrentDependanciesContainer>
			)
		);
	};

	const showAllDependancies = () => {
		let allDependancies = Array.isArray(currentDependacies)
			? currentDependacies
			: [currentDependacies];

		return (
			<>
				<Divider
					variant={'middle'}
					sx={{ '&::before': { width: '0' }, '&': { marginLeft: '5px' } }}
				>
					Applied Rules
				</Divider>
				<label data-testid={'conditionalLogic-currendDependancy-label'}>
					<StyledWrapper.ShowIf>SHOW IF</StyledWrapper.ShowIf>
				</label>
				{Array.isArray(allDependancies) && allDependancies[0] === undefined && (
					<Alert variant="outlined" severity="info">
						No business logic has been applied to this form element.
						<br />
						Use the options below to add a rule.
					</Alert>
				)}
				{allDependancies?.map((dependency, index) => {
					return Array.isArray(dependency) ? (
						<>
							<StyledWrapper.GroupedDependancies>
								{dependency?.map((innerDependency, innerIndex) => (
									<>
										{showCurrentDependancy(innerDependency, index, innerIndex)}
										{innerIndex !== dependency?.length - 1 && (
											<StyledWrapper.AndOrLabel>OR</StyledWrapper.AndOrLabel>
										)}
									</>
								))}
							</StyledWrapper.GroupedDependancies>
							{dependency?.map((innerDependency, innerIndex) => (
								<>
									{innerIndex === dependency?.length - 1 &&
										index !== allDependancies?.length - 1 && (
											<StyledWrapper.AndOrLabel>AND</StyledWrapper.AndOrLabel>
										)}
								</>
							))}
						</>
					) : (
						<>
							{showCurrentDependancy(dependency, index)}
							{index !== allDependancies?.length - 1 && (
								<StyledWrapper.AndOrLabel>AND</StyledWrapper.AndOrLabel>
							)}
						</>
					);
				})}
			</>
		);
	};

	return (
		<>
			<StyledWrapper.AllDependancies>{showAllDependancies()}</StyledWrapper.AllDependancies>

			<Collapse in={alertBalloon}>
				<Alert
					severity="error"
					action={
						<IconButton
							aria-label="close"
							color="error"
							size="small"
							onClick={() => {
								setAlertBalloon(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2 }}
				>
					{alertBalloon?.message}
				</Alert>
			</Collapse>
			<Divider variant={'middle'} sx={{ '&::before': { width: '0' }, '&': { marginLeft: '5px' } }}>
				Rules Options
			</Divider>
			<div className={'block-logical'}>
				<StyledWrapper.LogicContainer>
					<div className={'block-75'}>
						{currentDependacies?.length > 0 && (
							<StyledWrapper.InputApplyRuleContainer>
								<StyledWrapper.EditSelect variant="outlined" fullWidth>
									<InputLabel
										variant="outlined"
										htmlFor="uncontrolled-native"
										sx={{ top: '-10px', fontSize: '20px' }}
									>
										And/Or
									</InputLabel>
									<NativeSelect
										inputProps={{
											name: 'value',
											id: 'uncontrolled-native-and-or',
											color: 'warning',
											ref: andOrRef
										}}
										data-testid={'conditionalLogic-and-or-select'}
										sx={{ backgroundColor: '#fff' }}
									>
										<option value={'and'}>and</option>
										<option value={'or'}>or</option>
									</NativeSelect>
								</StyledWrapper.EditSelect>
							</StyledWrapper.InputApplyRuleContainer>
						)}
						<StyledWrapper.InputApplyRuleContainer>
							<StyledWrapper.EditSelect variant="outlined" fullWidth>
								<InputLabel
									variant="outlined"
									htmlFor="uncontrolled-native"
									sx={{ top: '-10px', fontSize: '20px' }}
								>
									Related Field
								</InputLabel>
								<NativeSelect
									inputProps={{
										name: 'relatedField',
										id: 'uncontrolled-native-relatedField',
										color: 'warning',
										ref: unCtlNativeRelatedFieldRef
									}}
									sx={{ backgroundColor: '#fff' }}
									data-testid={'conditionalLogic-field-select'}
									onChange={(data) => {
										setValue({ field: data.target.value, condition: '', value: '', option: '' });
										setRefresh(!refresh);
									}}
								>
									<option value hidden>
										unspecified
									</option>
									{fields.map((item, index) => {
										if (item.label && item.label !== 'File Attachment (Applicant Download)') {
											return (
												<option
													key={`relatedFields-${index}`}
													data-testid={'conditionalLogic-field-option'}
													value={item.field_name}
												>
													{stripTextHTML(item?.label)}{' '}
													{item?.unique_identifier
														? `(ff-${item?.unique_identifier})`
														: `(tmp-${item?.temp_order})`}
												</option>
											);
										}
									})}
								</NativeSelect>
							</StyledWrapper.EditSelect>
						</StyledWrapper.InputApplyRuleContainer>

						<StyledWrapper.InputApplyRuleContainer id="popy">
							<StyledWrapper.EditSelect variant="outlined" fullWidth>
								<InputLabel
									variant="outlined"
									htmlFor="uncontrolled-native"
									sx={{ top: '-10px', fontSize: '20px' }}
								>
									Condition
								</InputLabel>
								<NativeSelect
									inputProps={{
										name: 'condition',
										id: 'uncontrolled-native-condition',
										color: 'warning',
										ref: unCtlNativeConditionRef
									}}
									data-testid={'conditionalLogic-condition-select'}
									sx={{ backgroundColor: '#fff' }}
									onChange={(data) => {
										setValue({ ...values, condition: data.target.value, value: '', option: '' });
									}}
									value={values?.condition}
								>
									<option value hidden>
										unspecified
									</option>
									{(chosenField?.element === 'Dropdown' ||
										chosenField?.key === 'StatePicker' ||
										chosenField?.element === 'RadioButtons' ||
										chosenField?.element === 'Checkboxes' ||
										chosenField?.element === 'TextInput' ||
										chosenField?.key === 'MUI_TextArea' ||
										chosenField?.key === 'MUI_Input' ||
										chosenField?.key === 'MUI_Checkbox' ||
										chosenField?.key === 'MUI_RadioButton' ||
										chosenField?.key === 'MUI_Dropdown') && (
										<>
											<option value="is" data-testid={'conditionalLogic-condition-option'}>
												is
											</option>
											<option value="not" data-testid={'conditionalLogic-condition-option'}>
												{"isn't"}
											</option>
										</>
									)}

									{(chosenField?.element === 'TextInput' ||
										chosenField?.key === 'MUI_TextArea' ||
										chosenField?.key === 'MUI_Input') && (
										<>
											<option value="contains" data-testid={'conditionalLogic-condition-option'}>
												contains
											</option>
											<option
												value="not-contains"
												data-testid={'conditionalLogic-condition-option'}
											>
												does not contain
											</option>
											<option value="starts-with" data-testid={'conditionalLogic-condition-option'}>
												starts with
											</option>
											<option value="ends-with" data-testid={'conditionalLogic-condition-option'}>
												ends with
											</option>
										</>
									)}

									{['MUI_NumberInput', 'MUI_Dropdown']?.includes(chosenField?.key) && (
										<>
											<option value="min" data-testid={'conditionalLogic-condition-option'}>
												is greater than or equal to
											</option>
											<option value="max" data-testid={'conditionalLogic-condition-option'}>
												is less than or equal to
											</option>
										</>
									)}

									{chosenField?.element === 'DatePicker' && (
										<>
											<option value="since" data-testid={'conditionalLogic-condition-option'}>
												since
											</option>
											<option value="before" data-testid={'conditionalLogic-condition-option'}>
												before
											</option>
										</>
									)}

									<option value="exists" data-testid={'conditionalLogic-condition-option'}>
										exists
									</option>
									<option value="does not exist" data-testid={'conditionalLogic-condition-option'}>
										does not exist
									</option>
								</NativeSelect>
							</StyledWrapper.EditSelect>
						</StyledWrapper.InputApplyRuleContainer>

						<StyledWrapper.InputApplyRuleContainer>
							{['is', 'not', 'min', 'max']?.includes(values?.condition) ? (
								chosenField?.element === 'Dropdown' ||
								chosenField?.key === 'StatePicker' ||
								chosenField?.element === 'RadioButtons' ||
								chosenField?.element === 'Checkboxes' ||
								chosenField?.key === 'MUI_Checkbox' ||
								chosenField?.key === 'MUI_RadioButton' ||
								chosenField?.key === 'MUI_Dropdown' ? (
									<>
										<StyledWrapper.EditSelect variant="outlined" fullWidth>
											<InputLabel
												variant="outlined"
												htmlFor="uncontrolled-native"
												sx={{ top: '-10px', fontSize: '20px' }}
											>
												Value
											</InputLabel>
											<NativeSelect
												inputProps={{
													name: 'value',
													id: 'uncontrolled-native-value',
													color: 'warning',
													ref: unCtlNativeValueRef
												}}
												data-testid={'conditionalLogic-value-select'}
												sx={{ backgroundColor: '#fff' }}
												onChange={(data) => {
													setValue({
														...values,
														value: data.target.value,
														optionText: data?.target.options[data?.target?.selectedIndex].text
													});
												}}
											>
												<option value hidden>
													unspecified
												</option>
												{chosenField?.options?.map((e, index) => {
													return (
														<option
															key={`chosenFields-conditionalLogic-${index}`}
															data-testid={'conditionalLogic-value-option'}
															value={
																chosenField?.element === 'Dropdown' ||
																chosenField?.key === 'StatePicker'
																	? e?.value
																	: e?.key
															}
														>
															{e?.text}
														</option>
													);
												})}
											</NativeSelect>
										</StyledWrapper.EditSelect>
									</>
								) : (
									<>
										<StyledWrapper.EditTextField
											onChange={(data) => {
												setValue({ ...values, value: data.target.value });
											}}
											data-testid={'conditionalLogic-value-label'}
											label="Value"
											variant="outlined"
											color="warning"
											id="uncontrolled-native-value"
											ref={unCtlNativeValueRef}
											type={chosenField?.key === 'MUI_NumberInput' ? 'number' : 'text'}
											defaultValue="unspecified"
										/>
									</>
								)
							) : null}

							{['contains', 'not-contains', 'starts-with', 'ends-with']?.includes(
								values?.condition
							) && (
								<>
									<StyledWrapper.EditTextField
										onChange={(data) => {
											setValue({ ...values, value: data.target.value });
										}}
										data-testid={'conditionalLogic-value-label'}
										label="Value"
										variant="outlined"
										color="warning"
										defaultValue="unspecified"
									/>
								</>
							)}
						</StyledWrapper.InputApplyRuleContainer>
					</div>
					<div className={'block-25'}>
						<StyledWrapper.ButtonsApplyRuleContainer>
							<Tooltip title="Add" placement="bottom">
								<Button
									variant="contained"
									data-testid={'conditionalLogic-save-button'}
									aria-label="addDependancy"
									onClick={() => saveDependancy()}
									startIcon={
										<AddCircleIcon fontSize="small" data-testid={'conditionalLogic-save-icon'} />
									}
								>
									APPLY RULE
								</Button>
							</Tooltip>
						</StyledWrapper.ButtonsApplyRuleContainer>
					</div>
				</StyledWrapper.LogicContainer>
			</div>
		</>
	);
});

CustomLogic.displayName = 'CustomLogic';
